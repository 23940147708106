<template>
  <page-main
    title="Strengths"
    nextText="Final thoughts"
    buttonTabIndex="3"
    eventName="nav"
    @nav="nav('/FinalInfo')"
    @save="save()"
  >
    <template v-slot:aside>
      <p>
        We're going to group your strengths and skills together to better
        highlight them.
      </p>
      <p>
        Group similar skills into up to three groups. Target 3-4 skills or
        strengths per group.
      </p>
    </template>
    <v-container fluid class="container-main">
      <v-row>
        <v-col
          cols="12"
          class="r-col"
          v-for="(item, i) in fullList"
          :key="item.key"
        >
          <list-card
            :title="item.display"
            @orderup="orderUp(item.key)"
            @orderdown="orderDown(item.key)"
            :disableUp="i === 0"
            :disableDown="i === fullList.length - 1"
          >
            <div class="d-flex justify-space-between align-center">
              <div>
                <div v-if="HasValue(item, 'display')">
                  {{ item.display }}
                </div>
                <div v-if="HasValue(item, 'showCase')" class="pt-2">
                  Experiences
                  <div class="text-body-2 pl-2">{{ item.showCase }}</div>
                </div>
                <div v-if="HasValue(item, 'howApply')" class="pt-2">
                  Application
                  <div class="text-body-2 pl-2">{{ item.howApply }}</div>
                </div>
              </div>
              <div class="d-flex flex-column align-start">
                <v-btn
                  tile
                  text
                  @click="navWithKey('/StrengthsCategory/', item.key)"
                >
                  <v-icon class="pr-2"> mdi-pencil-outline </v-icon>
                  Edit
                </v-btn>
                <v-btn tile text @click.stop="launchDialog(item)">
                  <v-icon class="pr-2">mdi-delete</v-icon>
                  Remove
                </v-btn>
              </div>
            </div>
          </list-card>
        </v-col>
      </v-row>
      <v-row justify="center" class="pt-4">
        <v-col cols="auto">
          <v-btn outlined @click="navNewRecord()">Add Strength</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <r-dialog v-model="dialog" title="You are about to remove:">
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <p class="text-body-1 text--primary">{{ dialogDisplay }}</p>
        <span class="text-body-2">Are you sure?</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray" text @click="dialog = false"> Cancel </v-btn>
        <v-btn color="error" text @click="removeDialogItem()"> Remove </v-btn>
      </v-card-actions>
    </r-dialog>
  </page-main>
</template>

<script>
// @ is an alias to /src
import * as uuid from "../utility/guid";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import ListCard from "../components/ListCard.vue";
import { HasValue } from "../utility/general";

export default {
  name: "Strengths",
  data: () => ({
    dialog: false,
    dialogKey: "",
    dialogDisplay: "",
  }),
  components: {
    "list-card": ListCard,
  },
  mounted() {
    if (this.fullList?.length > 0) {
      this.key = this.fullList[0].key;
    }
  },
  methods: {
    save() {
      this.saveStrengths();
    },
    ...mapActions("strengths", ["saveStrengths"]),
    ...mapMutations("strengths", [
      "createEntry",
      "removeEntry",
      "orderUp",
      "orderDown",
    ]),
    HasValue,
    navWithKey(path, key) {
      this.key = key;
      this.nav(path + key);
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    navNewRecord() {
      this.key = uuid.gen();
      this.createEntry({ key: this.key });
      this.$router.push({ path: "/StrengthsCategory/" + this.key });
    },
    launchDialog(item) {
      this.dialogDisplay = item.display;
      this.dialogKey = item.key;
      this.dialog = true;
    },
    removeDialogItem() {
      this.removeEntry({ key: this.dialogKey });
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters("strengths", ["fullList"]),
    ...mapFields("strengths", {
      key: "key",
      skills: "skills",
      showCase: "showCase",
      howApply: "howApply",
      display: "display",
    }),
  },
};
</script>
